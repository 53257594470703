import * as Sentry from '@sentry/browser';

declare global {
  export interface Window {
    ringTr?: string;
    tst?: string;
  }
}

const areRequiredConsentsEnabled = (consentList: String[], values: string[]): boolean => {
  return values.every((item) => consentList.includes(item));
};

export const isRequiredUserConsent = (): boolean => {
  const requiredVendorsForConsent = ['c:czechnews-nJYjk3Bf'];
  const requiredPurposesForConsent = ['cookies', 'publishers-Kqq3iPge'];
  const userStatus = window.Didomi.getUserStatus();

  const vendorsEnabled = areRequiredConsentsEnabled(
    userStatus.vendors.consent.enabled,
    requiredVendorsForConsent
  );

  const purposesEnabled = areRequiredConsentsEnabled(
    userStatus.purposes.consent.enabled,
    requiredPurposesForConsent
  );

  return vendorsEnabled && purposesEnabled;
};

export const trackerRing = () => {
  try {
    // window.ringTr is unique tracking code for magazine template: def-footer-measuring-codes.twig
    if (window.ringTr) {
      window.didomiOnReady = window.didomiOnReady || [];
      window.didomiOnReady.push(() => {
        void (() => {
          const allConsentsFullfiled = isRequiredUserConsent();

          if (allConsentsFullfiled) {
            const cncHost = 'https://stat.cncenter.cz';

            // window.tst is unix timestamp for tracking template: def-footer-measuring-codes.twig
            const cncTst: string | number =
              typeof window.tst === 'undefined' ? new Date().getTime() : window.tst;

            const url: string = `${cncHost}/tr/${window.ringTr}?tst=${cncTst}`;
            const img = new Image();
            img.style.setProperty('position', 'absolute');
            img.style.setProperty('top', '-9999px');
            img.src = url;
            document.body.appendChild(img);
          }
        })();
      });
    }
  } catch (err) {
    Sentry.captureException(err);
    throw Error('Error with tracker.');
  }
};

export default trackerRing;
